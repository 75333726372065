@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .table-y-spacing-2 {
    border-spacing: 0 theme("spacing.2");
  }
}

html {
  background-color: #f4f5f6;
}

.material-symbols-filled {
  font-variation-settings: "FILL" 1;
}
